import { Pipe, PipeTransform } from '@angular/core';
import { Locale } from '@roctavian-abstractions/core';
import { Prescriber } from '../../prescriber/models/prescriber.model';
import { DuplicateLocaleCodes } from '../enums/duplicate-locale-codes';
import { Stakeholder } from '../models';

@Pipe({
  name: 'countryBasedLocale',
})
export class CountryBasedLocalePipe implements PipeTransform {
  transform(
    localeList: Locale[],
    country: any,
    stakeholder?: Stakeholder,
    prescriber?: Prescriber
  ): any {
    let filteredLocaleList: Locale[] = [];
    let duplicateLocaleCodes: DuplicateLocaleCodes;

    let filterCountry = country;

    if (prescriber) {
      filterCountry = prescriber.institution.stakeholder.address.countryId;
    } else if (stakeholder && stakeholder.address) {
      filterCountry = stakeholder.address.countryId;
    }

    if (!filterCountry) {
      return localeList;
    }

    if (filterCountry.hasOwnProperty('id')) {
      filteredLocaleList = localeList.filter(
        x => x.countryId.toLowerCase() === filterCountry.id.toLowerCase()
      );
    } else {
      filteredLocaleList = localeList.filter(
        x => x.countryId.toLowerCase() === filterCountry.toLowerCase()
      );
    }

    if (filteredLocaleList.length === 0) {
      filteredLocaleList = localeList.filter(
        supportedCountries =>
          supportedCountries.localeCode !== DuplicateLocaleCodes.germany &&
          supportedCountries.localeCode !== DuplicateLocaleCodes.austria &&
          supportedCountries.localeCode !== DuplicateLocaleCodes.portugal &&
          supportedCountries.localeCode !== DuplicateLocaleCodes.spain &&
          supportedCountries.localeCode !== DuplicateLocaleCodes.france
      );
    }

    filteredLocaleList.sort((a, b) => {
      const nameA = a.name.split(' - ')[1];
      const nameB = b.name.split(' - ')[1];
      return nameA.localeCompare(nameB);
  });
  
    return filteredLocaleList;
  }
}
